var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stage-adjustment"},[_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"done-case"},[_vm._v(_vm._s(_vm.$t("stageAdjust.jdtz")))]),_c('div',{staticClass:"clinic-name"},[_vm._v(" "+_vm._s(_vm.$t("cases.maintain.zsmc"))+": "+_vm._s(_vm.caseDetail.clinicName)+" ")]),_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(_vm.$t("cases.maintain.cpmc"))+": "+_vm._s(_vm.caseDetail.productName)+" ")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"option"},[_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:['tabs-item', _vm.nowTabIndex === index && 'active-tabs-item'],on:{"click":function($event){return _vm.changeTabIndex(index)}}},[_c('img',{staticClass:"tabs-icon",attrs:{"src":("/static/images/adjustment/" + (item.icon) + ".png"),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"option-control"},[(_vm.nowTabIndex !== 0)?_c('div',{staticClass:"previous-page",on:{"click":function($event){return _vm.changeTabIndex(_vm.nowTabIndex - 1)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.maintain.syy"))+" ")]):_vm._e(),(_vm.nowTabIndex !== 2)?_c('div',{staticClass:"next-page",on:{"click":function($event){return _vm.changeTabIndex(_vm.nowTabIndex + 1)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.maintain.xyy"))+" ")]):_vm._e(),_c('div',{class:[
            _vm.nowTabIndex !== 2
              ? 'staging'
              : _vm.nowTabIndex === 2 && _vm.canSubmit
              ? 'submit'
              : 'disabled-submit'
          ],on:{"click":_vm.modifyOrSave}},[_vm._v(" "+_vm._s(_vm.nowTabIndex !== 2 ? _vm.$t("cases.maintain.zc") : _vm.$t("cases.maintain.tj"))+" ")])])]),(!_vm.isLoading)?_c('div',[(_vm.nowTabIndex === 0)?_c('adjustment-table',{ref:"treatment",attrs:{"caseDetail":_vm.caseDetail,"caseOptions":_vm.caseOptions,"cureProblemList":_vm.cureProblemList,"questionName":_vm.questionName}}):_vm._e(),(_vm.nowTabIndex === 1)?_c('image-modal',{ref:"imageModal",attrs:{"caseDetail":_vm.caseDetail,"caseOptions":_vm.caseOptions,"cureProblemList":_vm.cureProblemList,"questionName":_vm.questionName}}):_vm._e(),(_vm.nowTabIndex === 2)?_c('Preview',{ref:"preview",attrs:{"caseDetail":_vm.caseDetail,"diaKeyObj":_vm.diaKeyObj,"dictObj":_vm.caseOptions},on:{"handleCanSubmit":_vm.handleCanSubmit,"jumpQuestion":_vm.jumpQuestion}}):_vm._e()],1):_vm._e()]),_c('confirm-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogVisible),expression:"dialogVisible"}],attrs:{"confirmObj":{
      tipText: _vm.$t('cases.casesCreate.sftj'),
      backText: _vm.$t('casesDetail.casesChildren.common.qx'),
      okText: _vm.$t('cases.casesCreate.qdtj')
    }},on:{"confirmHide":function($event){_vm.dialogVisible = false},"confirmSubmit":function($event){return _vm.submitTable()}}}),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.confirmLoading),expression:"confirmLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }